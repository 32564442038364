import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Sidebar/Header";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ViewVendorPay.css";

const VendorPayment = () => {
  // Helper functions to get current date and time
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hh = String(now.getHours()).padStart(2, "0");
    const mm = String(now.getMinutes()).padStart(2, "0");
    return `${hh}:${mm}`;
  };

  // Initial form data with consistent naming
  const initialFormData = {
    vendorId: "", // Renamed from selectedVendor
    vendorName: "", // To store the vendor's name
    quotation_Id: "", // To store the quotation ID
    event_name: "",
    event_date: "",
    date: getCurrentDate(),
    time: getCurrentTime(),
    bankName: "",
    account_number: "",
    Account_Holder_Name: "",
    advancePayment: 0,
    remainingPayment: 0,
    description: "",
    paymentStatus: "Pending", // Default status
    customer_Id: "", // If applicable, else can be omitted or set dynamically
    customerName: "", // If applicable
    totalStockPrice: 0, // To store total price
  };

  const [formData, setFormData] = useState(initialFormData);
  const [vendors, setVendors] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEventData, setSelectedEventData] = useState(null);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState({});
  const [errors, setErrors] = useState({ advancePayment: "", remainingPayment: "" });

  // Fetch vendor data when the component mounts
  const fetchVendors = async () => {
    try {
      const response = await axios.get('https://backend.ssdpune.org/api/addvendor'); // Example vendor API
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Function to handle vendor selection and fetch payment details for the selected vendor
  const handleVendorChange = async (e) => {
    const vendorId = e.target.value;
    const selectedVendor = vendors.find(vendor => vendor._id === vendorId);

    if (selectedVendor) {
      setFormData({
        ...formData,
        vendorId: selectedVendor._id,
        vendorName: selectedVendor.Vendor_Name,
        // Reset event and payment details when vendor changes
        quotation_Id: "",
        event_name: "",
        event_date: "",
        totalStockPrice: 0,
        advancePayment: 0,
        remainingPayment: 0,
      });

      // Fetch data from the vendor payment API for selected vendor
      try {
        const response = await axios.get(`https://backend.ssdpune.org/api/vendorpayment/pending/${vendorId}`);
        const vendorData = response.data;

        // Extract unique event names from the vendor data
        const eventOptions = vendorData.map((data) => ({
          event_name: data.event_name,
          quotation_Id: data.quotation_Id,
          totalStockPrice: data.totalStockPrice,
          event_date: data.event_date,
        }));

        // Remove duplicate events based on quotation_Id or event_name if necessary
        const uniqueEvents = Array.from(
          new Map(eventOptions.map(item => [item.quotation_Id, item])).values()
        );

        // Set the event dropdown options
        setEvents(uniqueEvents);
      } catch (error) {
        console.error('Error fetching vendor payment data:', error);
      }
    }
  };

  // Function to handle event selection
  const handleEventChange = (e) => {
    const selectedQuotationId = e.target.value;
    const selectedEvent = events.find(event => event.quotation_Id === selectedQuotationId);

    if (selectedEvent) {
      setSelectedEventData(selectedEvent); // Set selected event data
      setFormData({
        ...formData,
        quotation_Id: selectedEvent.quotation_Id,
        event_name: selectedEvent.event_name,
        event_date: selectedEvent.event_date,
        totalStockPrice: selectedEvent.totalStockPrice,
        advancePayment: 0, // Reset advance payment when event changes
        remeaningPayment: selectedEvent.totalStockPrice, // Initially, remaining payment is total
      });
      setErrors({ ...errors, advancePayment: "", remainingPayment: "" }); // Reset errors
    }
  };

  // Fetch banks data when the component mounts
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get("https://backend.ssdpune.org/api/allbanks");
        if (Array.isArray(response.data)) {
          setBanks(response.data);
        } else {
          console.error("Banks data is not an array:", response.data);
        }
      } catch (error) {
        console.error("Error fetching bank data:", error);
      }
    };

    fetchBanks();
  }, []);

  // Handle bank selection from dropdown
  const handleBankChange = (e) => {
    const selectedBankId = e.target.value;
    const selected = banks.find((bank) => bank._id === selectedBankId);

    if (selected) {
      setSelectedBank(selected); // Update selected bank details
      setFormData({
        ...formData,
        bankName: selected.Bank_Name,
        Account_Holder_Name: selected.Account_Holder_Name,
        account_number: selected.Account_Number,
      });
    }
  };

  // Calculate remaining amount when totalStockPrice or advancePayment changes
  useEffect(() => {
    const totalStockPrice = parseFloat(formData.totalStockPrice) || 0;
    const advancePayment = parseFloat(formData.advancePayment) || 0;

    if (advancePayment > totalStockPrice) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        advancePayment: "Advance payment cannot be greater than total amount",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        advancePayment: "",
      }));

      const remainingAmount = totalStockPrice - advancePayment;
      setFormData((prevFormData) => ({
        ...prevFormData,
        remainingPayment: remainingAmount,
      }));
    }
  }, [formData.totalStockPrice, formData.advancePayment]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // For number fields, ensure that the value is a number
    const updatedValue = name === "advancePayment" ? parseFloat(value) : value;

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  // handleSave function to handle the API request
  const handleSave = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Basic validation before submission
    if (!formData.vendorId) {
      alert("Please select a vendor.");
      return;
    }
    if (!formData.quotation_Id) {
      alert("Please select an event.");
      return;
    }
    if (!formData.bankName) {
      alert("Please select a bank.");
      return;
    }
    if (formData.advancePayment > formData.totalStockPrice) {
      alert("Advance payment cannot be greater than total amount.");
      return;
    }

    // Prepare the data to match the desired JSON structure
    const submissionData = {
      vendorId: formData.vendorId,
      vendorName: formData.vendorName,
      quotation_Id: formData.quotation_Id,
      event_name: formData.event_name,
      event_date: formData.event_date,
      date: formData.date,
      time: formData.time,
      bankName: formData.bankName,
      account_number: formData.account_number,
      Account_Holder_Name: formData.Account_Holder_Name,
      advancePayment: formData.advancePayment,
      remeaningPayment: formData.remainingPayment,
      description: formData.description,
      totalStockPrice: formData.totalStockPrice,
      paymentStatus: formData.remainingPayment === 0 ? "Paid" : "Pending",
      customer_Id :formData.customer_Id,
      customerName:formData.customerName,
    };

    try {
      const response = await axios.post("https://backend.ssdpune.org/api/vendorpayment", submissionData);
      alert("Vendor Payment added successfully");
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting payment:', error);
      // Optionally set error message state or notify the user
      alert("Error submitting payment. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:w-[50%]">
          <Form onSubmit={handleSave}>
            <div className="flex">
              <Link to={"/eventmanager/viewvendorpayment"}>
                <button type="button" className="btn btn-primary mr-4 mb-4">
                  View Vendor Payment Details
                </button>
              </Link>
            </div>

            <h2 className="text-[30px] pl-[1em]">Vendor Payment</h2>

            {/* Vendor Selection */}
            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="SelectVendor">
                  <Form.Label>Select Vendor:<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Select
                    className="w-full py-2 pl-3 pr-10 border-gray-300 rounded-2xl focus:outline-none focus:ring focus:ring-indigo-400 focus:border-indigo-400"
                    aria-label="Select Vendor"
                    name="vendorId"
                    onChange={handleVendorChange}
                    value={formData.vendorId}
                    required
                  >
                    <option value="">Select Vendor</option>
                    {vendors.map((vendor) => (
                      <option key={vendor._id} value={vendor._id}>
                        {vendor.Vendor_Name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            {/* Event Selection */}
            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="SelectEvent">
                  <Form.Label>Select Event:<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Select
                    className="w-full py-2 pl-3 pr-10 border-gray-300 rounded-2xl focus:outline-none focus:ring focus:ring-indigo-400 focus:border-indigo-400"
                    aria-label="Select Event"
                    name="quotation_Id"
                    onChange={handleEventChange}
                    value={formData.quotation_Id}
                    required
                    disabled={!formData.vendorId}
                  >
                    <option value="">Select Event</option>
                    {events.map((event) => (
                      <option key={event.quotation_Id} value={event.quotation_Id}>
                        {event.event_name} - {event.event_date}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            {/* Date and Time */}
            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="date">
                  <Form.Label>
                    Date:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="time">
                  <Form.Label>
                    Time:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
            </div>

            {/* Bank Selection */}
            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="SelectBank">
                  <Form.Label>
                    Select Bank:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Select
                    className="w-full py-2 pl-3 pr-10 border-gray-300 rounded-2xl focus:outline-none focus:ring focus:ring-indigo-400 focus:border-indigo-400"
                    aria-label="Select Bank"
                    name="bank"
                    onChange={handleBankChange}
                    value={selectedBank._id || ""}
                    required
                  >
                    <option value="">Select Bank</option>
                    {banks.map((bank) => (
                      <option key={bank._id} value={bank._id}>
                        {bank.Bank_Name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="bankAccount_Name">
                  <Form.Label>Bank Account Holder Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="Account_Holder_Name"
                    value={formData.Account_Holder_Name}
                    readOnly
                  />
                </Form.Group>
              </div>
            </div>

            {/* Account Number and Paid Amount */}
            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="account_number">
                  <Form.Label>Account Number:</Form.Label>
                  <Form.Control
                    type="text"
                    name="account_number"
                    value={formData.account_number}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="paid_amt">
                  <Form.Label>
                    Total Amount:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="totalStockPrice"
                    value={formData.totalStockPrice}
                    readOnly
                  />
                </Form.Group>
              </div>
            </div>

            {/* Advance Payment and Remaining Payment */}
            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="advance_payment">
                  <Form.Label>
                    Advance Payment:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="advancePayment"
                    value={formData.advancePayment}
                    onChange={handleInputChange}
                    isInvalid={!!errors.advancePayment}
                    min="0"
                    max={formData.totalStockPrice}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.advancePayment}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="remainingPayment">
                  <Form.Label>
                    Remaining Amount:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="remeaningPayment"
                    value={formData.remainingPayment}
                    readOnly
                    isInvalid={formData.remainingPayment < 0}
                  />
                  <Form.Control.Feedback type="invalid">
                    Remaining amount cannot be negative.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            {/* Description */}
            <div className="mb-4">
              <Form.Group controlId="description">
                <Form.Label>Description:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter description here"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>

            {/* Submit Button */}
            <div className="text-center mb-4">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default VendorPayment;

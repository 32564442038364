import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { Button, Modal, Form } from 'react-bootstrap';
import Header from "../Sidebar/Header";

const VendorPaymentDetails = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [remainPayment, setRemainPayment] = useState("");

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch('https://backend.ssdpune.org/api/vendorpayment');
        if (!response.ok) {
          throw new Error('Failed to fetch payments');
        }
        const data = await response.json();
        setPayments(data); // assuming data is an array of payments
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPayments();
  }, []);

  const handleEdit = (payment) => {
    setSelectedPayment(payment);
    setRemainPayment(""); // Reset the remaining payment input
    setShowPopup(true);
  };

  const handleCancel = () => {
    setShowPopup(false);
    setSelectedPayment(null);
  };

  const handlePayAmount = (e) => {
    setRemainPayment(e.target.value);
  };

  const handlePay = async () => {
    if (!selectedPayment) return;

    const updatedData = {
      remeaningPayment: remainPayment
    };

    try {
      setLoading(true);
      const response = await fetch(`https://backend.ssdpune.org/api/vendorpayment/update-remaining/${selectedPayment._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update payment');
      }

      const updatedPayment = await response.json();
      // Update the payments state with the updated payment
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment._id === updatedPayment.vendorPayment._id ? updatedPayment.vendorPayment : payment
        )
      );
      // Close the popup
      handleCancel();
      alert(updatedPayment.message);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:mt-0 w-[80%]">
          <div className="flex">
            <Link to="/eventmanager/vendorpayment">
              <button className="btn btn-primary mr-4 mb-4">
                Add Vendor Payment
              </button>
            </Link>
            <Link to="/eventmanager/viewvendorpayment">
              <button className="btn btn-primary mr-4 mb-4">
                View Vendor Payment Details
              </button>
            </Link>
          </div>
          <h2 className="text-[30px]">Vendor Payment Details</h2>

          <input
            type="search"
            placeholder="Search by Vendor Name"
            className="mb-4 p-2 border rounded w-[50%] flex justify-center"
          />

          {error && <div className="alert alert-danger">{error}</div>}
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="overflow-y-auto h-[70vh] md:mt-0 w-full">
              <table className="table table-bordered bg-white">
                <thead className="sticky top-0 bg-white">
                  <tr>
                    <th>Vendor Name</th>
                    <th>Event Name</th>
                    <th>Event Date</th>
                    <th>Total Price</th>
                    <th>Advance Payment</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.length > 0 ? (
                    payments.map((payment) => (
                      <tr key={payment._id}>
                        <td>{payment.vendorName}</td>
                        <td>{payment.event_name}</td>
                        <td>{payment.event_date}</td>
                        <td>{payment.totalStockPrice}</td>
                        <td>{payment.advancePayment}</td>
                        <td style={{ color: payment.paymentStatus === 'Pending' ? "red" : "green" }}>
                          {payment.paymentStatus}
                        </td>
                        <td>
                          <FaEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit(payment)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No matching records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          <div className="row mb-2">
            <div className="col">
              <div className="mt-3">
                <Button className="manager-btn ms-1">
                  Download PDF
                </Button>
                <Button className="manager-btn ms-4">
                  Download Excel
                </Button>
              </div>
            </div>
          </div>

          <Modal show={showPopup} onHide={handleCancel} className="model-payment-vendor">
            <Modal.Header closeButton>
              <Modal.Title>Edit Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formDate">
                  <Form.Label>Date</Form.Label>
                  <Form.Control type="date" value={selectedPayment ? selectedPayment.date.split('T')[0] : ""} readOnly />
                </Form.Group>
                <Form.Group controlId="formPayRemainingAmt">
                  <Form.Label>Remaining Amount</Form.Label>
                  <Form.Control type="number" value={selectedPayment ? selectedPayment.advancePayment : ""} readOnly />
                </Form.Group>
                <Form.Group controlId="formPayRemainingAmt">
                  <Form.Label>Pay Remaining Amount</Form.Label>
                  <Form.Control type="number" onChange={handlePayAmount} value={remainPayment} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handlePay}
                disabled={loading || !selectedPayment || remainPayment === ""}
              >
                {loading ? "Processing..." : "Pay"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default VendorPaymentDetails;

import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { format } from "date-fns";
import Header from "../Sidebar/Header";
import { FaEdit } from "react-icons/fa";
import axios from "axios";

const ViewAdvPay = () => {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [isnull, setisNull] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState("");

  // Use useEffect to set remainingAmount when selectedPayment is available
  useEffect(() => {
    if (selectedPayment) {
      setRemainingAmount(selectedPayment.rem_payment); // Set initial value to rem_payment
    }
  }, [selectedPayment]);

  const fetchPayments = async () => {
    const managerId = localStorage.getItem("managerId");
    try {
      const response = await fetch(
        `https://backend.ssdpune.org/api/advpayment/manager/${managerId}`
      );
      const data = await response.json();
      console.log("Fetched Payments Data:", data);
      if (data.message === "No payments found for this manager") {
        setisNull(true);
        return;
      }
      setPayments(data);
      setFilteredPayments(data);
      setisNull(false);
    } catch (e) {
      console.error(`Failed to fetch data: ${e}`);
    }
  };

  useEffect(() => {
    fetchPayments(); // Call the fetchPayments function here
  }, []);

  useEffect(() => {
    filterData(searchTerm, dateRange.startDate, dateRange.endDate);
  }, [searchTerm, dateRange]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setDateRange((prev) => ({ ...prev, startDate: event.target.value }));
  };

  const handleEndDateChange = (event) => {
    setDateRange((prev) => ({ ...prev, endDate: event.target.value }));
  };

  const filterData = (searchTerm, startDate, endDate) => {
    if (isnull) return;
    setisNull(false);

    let filtered = payments.filter((payment) => {
      const eventName = payment.event_name?.toLowerCase() || "";
      const clientName = payment.client_name?.toLowerCase() || "";
      const venue = payment.venue?.toLowerCase() || "";

      return (
        eventName.includes(searchTerm.toLowerCase()) ||
        clientName.includes(searchTerm.toLowerCase()) ||
        venue.includes(searchTerm.toLowerCase())
      );
    });

    if (startDate && endDate) {
      filtered = filtered.filter((payment) => {
        const eventDate = new Date(payment.event_date);
        return (
          eventDate >= new Date(startDate) && eventDate <= new Date(endDate)
        );
      });
    }

    setFilteredPayments(filtered);
  };

  const openPopup = (payment) => {
    setSelectedPayment(payment);
    setShowModal(true);
  };

  const closePopup = () => {
    setShowModal(false);
    setSelectedPayment(null);
  };

  const handlePaymentSubmit = async () => {
    try {
      const submittedAmount = Number(remainingAmount);

      // Validate the submitted amount
      if (isNaN(submittedAmount) || submittedAmount <= 0) {
        alert("Payment Already Done");
        return;
      }

      // Optionally disable the button or show loading state here
      // setLoading(true);

      const response = await axios.patch(
        `https://backend.ssdpune.org/api/new/advpayment/${selectedPayment._id}/rempayment`,
        {rem_payment_submitted: submittedAmount}
      );

      // Fetch updated payments and close modal
      fetchPayments();
      closePopup();
      alert(response.data.message);

      // Optionally reset loading state here
      // setLoading(false);
    } catch (error) {
      console.error("Error submitting payment:", error);
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred.";
      alert("Error submitting payment: " + errorMessage);

      // Optionally reset loading state here
      // setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount">
        <div className="md:h-[80vh] h-[80vh] md:mt-0 w-[80%]">
          <div className="filter-container">
            <input
              type="text"
              placeholder="Search Client Name, Venue, or Event Name"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
            <span>Start Date:</span>
            <input
              type="date"
              value={dateRange.startDate}
              onChange={handleStartDateChange}
            />
            <span>End Date:</span>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={handleEndDateChange}
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-[30px]">View Customer Payments</h2>
          </div>
          <div className="table-responsive md:w-full overflow-y-auto md:h-[60vh] h-[50vh] md:mt-0">
            <table className="table">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th scope="col">Client Name</th>
                  <th scope="col">Event Name</th>
                  <th scope="col">Event Venue</th>
                  <th scope="col">Event Date</th>
                  <th scope="col">Contact No.</th>
                  <th scope="col">Payment Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody
                className="mt-5"
                style={{ background: "white", borderRadius: "10px" }}
              >
                {isnull ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No payments found for this manager
                    </td>
                  </tr>
                ) : (
                  filteredPayments.map((payment) => (
                    <tr key={payment._id}>
                      <td>{payment.client_name}</td>
                      <td>{payment.event_name}</td>
                      <td>{payment.venue}</td>
                      <td>{formatDate(payment.event_date)}</td>
                      <td>{payment.contact}</td>
                      <td
                        style={{
                          color:
                            payment.payment_status === "pending"
                              ? "red"
                              : "green",
                          fontWeight:
                            payment.payment_status === "done"
                              ? "bold"
                              : "normal" }}
                      >
                        {payment.payment_status}
                      </td>
                      <td>
                        <FaEdit
                          style={{ cursor: "pointer" }}
                          onClick={() => openPopup(payment)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {selectedPayment && (
            <Modal
              show={showModal}
              onHide={closePopup}
              dialogClassName="modal-dialog-centered modal-dialog-responsive"
            >
              <Modal.Header>
                <Modal.Title>Edit Customer Payment Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p style={{ lineHeight: "35px" }}>
                    <strong>Client Name: </strong>{selectedPayment.client_name || ""}
                    <br />
                    <strong>Date:</strong> {formatDate(selectedPayment.event_date)}
                    <br />
                    <strong>Pending Amount:</strong> {selectedPayment.rem_payment}
                    <br />
                    <label htmlFor="remaining-amount">
                    <strong>Pay Remaining Payment:</strong>
                    </label>
                    <input
                      type="number"
                      id="rem_payment"
                      value={remainingAmount}
                      onChange={(e) => setRemainingAmount(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="close-button-popup" onClick={closePopup}>
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handlePaymentSubmit}
                >
                  Pay
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};
// Function to format date
const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date";
  }
};

export default ViewAdvPay;

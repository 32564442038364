import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { FaUser, FaCalendarAlt, FaShoppingCart } from "react-icons/fa";
import ApexCharts from "apexcharts/dist/apexcharts";
import Header from "./../Sidebar/Header";
import { format, isSameDay, parse } from "date-fns";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [tasks, setTasks] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [events, setEvents] = useState([]);
  const [filteredInquiries, setFilteredInquiries] = useState([]);

  const [enquiryCount, setEnquiryCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [enquiryTodayCount, setEnquiryTodayCount] = useState(0);
  const [eventTodayCount, setEventTodayCount] = useState(0);
  const [pendingEnquiryCount, setPendingEnquiryCount] = useState(0);


 // Function to fetch all enquiries once
// Function to fetch today's counts and total counts
const fetchEnquiries = async () => {
  const managerId = localStorage.getItem("managerId");
  if (!managerId) {
    console.error("Manager ID not found in localStorage.");
    return;
  }

  try {
    // Fetch total enquiries
    const enquiriesResponse = await fetch(`https://backend.ssdpune.org/api/enquiry/${managerId}`);
    if (!enquiriesResponse.ok) {
      throw new Error(`Error fetching enquiries: ${enquiriesResponse.statusText}`);
    }
    const enquiriesData = await enquiriesResponse.json();
    console.log("Fetched Enquiries:", enquiriesData);

    // Filter inquiries based on status
      const confirmedInquiries = enquiriesData.filter(inquiry => inquiry.status === "Confirm");
      const pendingInquiries = enquiriesData.filter(inquiry => inquiry.status === "pending");

      // Update state with filtered inquiries
      setFilteredInquiries(confirmedInquiries);
      setPendingEnquiryCount(pendingInquiries.length); // Count of pending enquiries


    // Fetch total events from manager API
    const eventsResponse = await fetch(`https://backend.ssdpune.org/api/event/manager/${managerId}`);
    if (!eventsResponse.ok) {
      throw new Error(`Error fetching events: ${eventsResponse.statusText}`);
    }
    const eventsData = await eventsResponse.json();
    console.log("Fetched Events:", eventsData);

    // Fetch today's enquiries count
    const todayEnquiriesResponse = await fetch(
      `https://backend.ssdpune.org/api/enquiry/manager/${managerId}/today/count`
    );
    if (!todayEnquiriesResponse.ok) {
      throw new Error(`Error fetching today's enquiries count: ${todayEnquiriesResponse.statusText}`);
    }
    const todayEnquiriesData = await todayEnquiriesResponse.json();
    setEnquiryTodayCount(todayEnquiriesData.count); // Assuming the response has { count: number }
    console.log("Today's Enquiries Count:", todayEnquiriesData.count);

    // Fetch today's events count
    const todayEventsResponse = await fetch(
      `https://backend.ssdpune.org/api/event/manager/${managerId}/today/count`
    );
    if (!todayEventsResponse.ok) {
      throw new Error(`Error fetching today's events count: ${todayEventsResponse.statusText}`);
    }
    const todayEventsData = await todayEventsResponse.json();
    setEventTodayCount(todayEventsData.count); // Assuming the response has { count: number }
    console.log("Today's Events Count:", todayEventsData.count);

    // Set total enquiries and events
    setEnquiryCount(enquiriesData.length); // Total Enquiries
    setEventCount(eventsData.length); // Total Events

    // Other processing like filtering and sorting can remain the same
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};


  // Fetching Tasks
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch("https://backend.ssdpune.org/api/executivetask");
        if (!response.ok) {
          throw new Error(`Error fetching tasks: ${response.statusText}`);
        }
        const data = await response.json();
        setTasks(data);
        console.log("Fetched Tasks:", data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, []);

  // Fetching Enquiries and Related Counts
  useEffect(() => {
    fetchEnquiries();
  }, []);

  // Pie Chart for Dashboard
  useEffect(() => {
    const pieData = {
      labels: ["Red", "Blue", "Yellow"],
      series: [300, 50, 100],
    };

    const options = {
      chart: {
        type: "pie",
        height: 350,
      },
      labels: pieData.labels,
      series: pieData.series,
      legend: {
        horizontalAlign: "left",
      },
    };

    // Check if the element exists to prevent errors
    const chartElement = document.querySelector("#pieChart");
    if (chartElement) {
      new ApexCharts(chartElement, options).render();
    }
  }, []);

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="row container-dashboard md:h-[80vh] h-[80vh]">
          {/* Dashboard Cards */}
          <div className="col-md-12">
            <div className="row">
              {/* Today's Enquiry */}
              <div className="col-md-4">
                <div className="card shadow custom-card">
                  <Link to="/eventmanager/quotation" className="dashboard">
                    <div className="card-body text-center">
                      <h5 className="card-title">
                        <FaUser
                          className="icon user-icon mb-2"
                          style={{ fontSize: "2rem" }}
                        />{" "}
                        Today's Enquiry
                      </h5>
                      <p className="card-text mb-0" style={{ fontSize: "1.5rem" }}>
                        {enquiryTodayCount}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* Today's Events */}
              <div className="col-md-4">
                <div className="card shadow custom-card">
                  <Link to="/eventmanager/viewevent" className="dashboard">
                    <div className="card-body text-center">
                      <h5 className="card-title">
                        <FaCalendarAlt
                          className="icon calendar-icon mb-2"
                          style={{ fontSize: "2rem" }}
                        />{" "}
                        Today's Events
                      </h5>
                      <p className="card-text mb-0" style={{ fontSize: "1.5rem" }}>
                        {eventTodayCount}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* Pending Enquiries */}
              <div className="col-md-4">
                <div className="card shadow custom-card">
                
                    <div className="card-body text-center">
                      <h5 className="card-title">
                        <FaShoppingCart
                          className="icon cart-icon mb-2"
                          style={{ fontSize: "2rem" }}
                        />{" "}
                        Pending Enquiries
                      </h5>
                      <p className="card-text mb-0" style={{ fontSize: "1.5rem" }}>
                        {pendingEnquiryCount}
                      </p>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>

          {/* Total Counts */}
          <div className="col-md-12 flex mt-4">
            {/* Total Enquiry */}
            <div className="col-md-6">
              <div className="card shadow custom-card">
                <Link to="/eventmanager/quotation" className="dashboard">
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      <FaUser
                        className="icon user-icon mb-2"
                        style={{ fontSize: "2rem" }}
                      />{" "}
                      Total Enquiry
                    </h5>
                    <p className="card-text mb-0" style={{ fontSize: "1.5rem" }}>
                      {enquiryCount}
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            {/* Total Events */}
            <div className="col-md-6">
              <div className="card shadow custom-card">
                <Link to="/eventmanager/viewevent" className="dashboard">
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      <FaCalendarAlt
                        className="icon calendar-icon mb-2"
                        style={{ fontSize: "2rem" }}
                      />{" "}
                      Total Events
                    </h5>
                    <p className="card-text mb-0" style={{ fontSize: "1.5rem" }}>
                      {eventCount}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* Enquiry Table and Pie Chart */}
          <div className="col-md-8 mt-4">
            <div className="activity-card overflow-y-auto h-[23.3em] rounded">
              <h2 className="activity-title font-bold mb-2">Enquiry</h2>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Event Name</th>
                    <th>Event Date</th>
                    <th>Name</th>
                    <th>Event Venue</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredInquiries.map((inquiry, index) => (
                    <tr key={inquiry._id || index}>
                      <td>{index + 1}</td>
                      <td>{inquiry.event_name}</td>
                      <td>{inquiry.event_date}</td>
                      <td>{inquiry.name || inquiry.customer_name || "N/A"}</td>
                      <td>{inquiry.event_venue || "N/A"}</td>
                      <td className="text-center">
                        <span
                          className={`badge fixed-width-badge bg-${inquiry.status === "Confirm" ? "success" : "danger"
                            }`}
                        >
                          {inquiry.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Pie Chart */}
          <div className="col-md-4 mt-4">
            <div className="card shadow">
              <div className="card-body">
                <h5 className="card-title">Pie Chart</h5>
                <div id="pieChart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;



import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { Link } from 'react-router-dom';

import Header from "../../Sidebar/Header";
import { Button, Modal } from "react-bootstrap";
import "./ViewEnquiry.css";

const CreateQuotation = () => {
  const [inquiries, setInquiries] = useState([]);
  const [filteredInquiries, setFilteredInquiries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedInquiry, setSelectedInquiry] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve manager ID from localStorage
        const managerId = localStorage.getItem("managerId");

        // Fetch inquiries associated with manager ID
        const response = await fetch(`https://backend.ssdpune.org/api/enquiry/${managerId}`);
        const data = await response.json();

        setInquiries(data);
        setFilteredInquiries(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = () => {
    const filtered = inquiries.filter((enquiry) => {
      const eventName = enquiry.event_name
        ? enquiry.event_name.toLowerCase()
        : "";
      const companyName = enquiry.company_name
        ? enquiry.company_name.toLowerCase()
        : "";
      const customerName = enquiry.customer_name
        ? enquiry.customer_name.toLowerCase()
        : "";

      return (
        eventName.includes(searchTerm.toLowerCase()) ||
        companyName.includes(searchTerm.toLowerCase()) ||
        customerName.includes(searchTerm.toLowerCase())
      );
    });

    setFilteredInquiries(filtered);
  };

  const handleDateRangeFilter = () => {
    const filtered = inquiries.filter((enquiry) => {
      const eventDate = new Date(enquiry.event_date);
      const startDate = dateRange.startDate
        ? new Date(dateRange.startDate)
        : null;
      const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;

      return (
        (!startDate || eventDate >= startDate) &&
        (!endDate || eventDate <= endDate)
      );
    });

    setFilteredInquiries(filtered);
  };

  const clearFilters = () => {
    setFilteredInquiries(inquiries);
    setSearchTerm("");
    setDateRange({ startDate: "", endDate: "" });
  };

  const [showModal, setShowModal] = useState(false);

  const openPopup = (enquiry) => {
    setSelectedInquiry(enquiry);
    setShowModal(true);
  };

  const closePopup = () => {
    setShowModal(false);
    setSelectedInquiry(null);
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:mt-0 w-[80%]">
        <div className="flex">
          <Link to={'/eventmanager/quotation'}>
          <button className="btn btn-primary mr-4 mb-4">View Enquiry</button>
          </Link>
          <Link to={'/eventmanager/createquotation'}>
          <button className="btn btn-primary mr-4 mb-4">Proposal</button>
          </Link>
          <Link to={'/eventmanager/followupstatus'}>
          <button className="btn btn-primary mr-4 mb-4">FollowUp Status</button>
          </Link>
          <Link to={'/eventmanager/addnewevent'}>
          <button className="btn btn-primary mr-4 mb-4">Add Event</button>
          </Link>
        </div>
          <div className="d-flex flex-wrap align-items-center">
            <h2 className="text-[30px]">Proposal</h2>
            
          </div>

          <div className="table-responsive w-[105%] md:w-full overflow-y-auto md:h-[60vh] h-[50vh] md:mt-0">
            <table className="table">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th scope="col">Event Name</th>
                  <th scope="col">Event Date</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody style={{ background: "white", borderRadius: "10px" }}>
                {filteredInquiries.map((enquiry, index) => (
                  <tr key={enquiry._id}>
                    <td>{enquiry.event_name || ""}</td>
                    <td>
                      {enquiry.event_date
                        ? format(new Date(enquiry.event_date), "dd/MM/yyyy")
                        : ""}
                    </td>
                    <td>{enquiry.customer_name}</td>
                    <td>{enquiry.contact}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => openPopup(enquiry)}
                      >
                        View More
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {selectedInquiry && (
            <Modal show={showModal} onHide={closePopup}>
              <Modal.Header closeButton>
                <Modal.Title>Inquiry Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedInquiry && (
                  <div>
                    <h2>{selectedInquiry.title}</h2>
                    <p style={{ lineHeight: "35px" }}>
                      Event Name: {selectedInquiry.event_name || ""}
                      <br />
                      Event Date:{" "}
                      {selectedInquiry.event_date
                        ? format(
                            new Date(selectedInquiry.event_date),
                            "dd/MM/yyyy"
                          )
                        : ""}
                      <br />
                      Number of Estimated Guests:{" "}
                      {selectedInquiry.guest_quantity}
                      <br />
                      Event Venue: {selectedInquiry.event_venue}
                      <br />
                      Event Requirement: {selectedInquiry.event_requirement}
                      <br />
                      Customer Name: {selectedInquiry.customer_name}
                      <br />
                      Customer Email: {selectedInquiry.email}
                      <br />
                      Contact Number: {selectedInquiry.contact}
                      <br />
                      Customer Address: {selectedInquiry.address}
                    </p>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateQuotation;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Sidebar/Header";
import { Modal, Button } from "react-bootstrap";
import * as XLSX from "xlsx";

const OutstandingReport = () => {
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [customerFilter, setCustomerFilter] = useState("");
  const [eventFilter, setEventFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customers, setCustomers] = useState([]);
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    // Fetch the data from the API when the component mounts
    fetch("https://backend.ssdpune.org/api/outstanding-report")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setReportData(data.data);
          setFilteredData(data.data);

          // Extract unique customers and events for dropdowns
          const uniqueCustomers = [
            ...new Set(data.data.map((item) => item.customerName)),
          ];
          const uniqueEvents = [
            ...new Set(data.data.map((item) => item.event_name)),
          ];

          setCustomers(uniqueCustomers);
          setEvents(uniqueEvents);
        }
      })
      .catch((error) => console.error("Error fetching report data:", error));
  }, []);

  // Handle filtering
  const handleFilter = () => {
    let filtered = reportData;

    if (customerFilter) {
      filtered = filtered.filter(
        (item) => item.customerName === customerFilter
      );
    }

    if (eventFilter) {
      filtered = filtered.filter((item) => item.event_name === eventFilter);
    }

    // Search functionality
    if (searchTerm) {
      filtered = filtered.filter(
        (item) =>
          item.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.event_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.contact.toString().includes(searchTerm)
      );
    }

    // Date filtering
    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.event_date) >= new Date(startDate)
      );
    }

    if (endDate) {
      filtered = filtered.filter(
        (item) => new Date(item.event_date) <= new Date(endDate)
      );
    }

    setFilteredData(filtered);
  };

  // Handle clear filters
  const handleClear = () => {
    setCustomerFilter("");
    setEventFilter("");
    setSearchTerm(""); // Clear search term
    setStartDate(""); // Clear start date
    setEndDate(""); // Clear end date
    setFilteredData(reportData);
  };
  const handleShowModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null); // Clear the selected item when closing the modal
  };

  // Function to export the filtered data to Excel
const exportToExcel = () => {
  // Map the filtered data to the required fields with proper headings
  const mappedData = filteredData.map((item) => ({
    "Customer Name": item.customerName,
    "Event Name": item.event_name,
    "Contact": item.contact,
    "Event Date": new Date(item.event_date).toLocaleDateString(), 
    "Total Bill": item.total_event_amount,
    "Payment Received": item.adv_payment,
    "Balance Amount": item.rem_payment,
    "Payment Status": item.payment_status
  }));

  // Create a new workbook and a worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(mappedData);

  // Set column widths for better readability
  const wscols = [
    { wch: 20 },  // Customer Name
    { wch: 20 },  // Event Name
    { wch: 15 },  // Contact
    { wch: 10 },  // Amount
    { wch: 15 },  // Event Date
    { wch: 15 },  // Amount Paid
    { wch: 15 },  // Payment Mode
    { wch: 15 }   // Payment Date
  ];
  ws['!cols'] = wscols;

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Outstanding Report");

  // Write the workbook to a file and trigger the download
  XLSX.writeFile(wb, "outstanding_report.xlsx");
};



  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:mt-0 w-[80%]">
          <div className="flex">
            {/* Navigation Links */}
            <Link to={"/admin/eventreport"}>
              <button className="btn btn-primary mr-4 mb-4">
                Event Report
              </button>
            </Link>
            <Link to={"/admin/enquiryreport"}>
              <button className="btn btn-primary mr-3 mb-4">
                Enquiry Report
              </button>
            </Link>
            <Link to={"/admin/customerreport"}>
              <button className="btn btn-primary mr-3 mb-4">
                Customer Report
              </button>
            </Link>
            <Link to={"/admin/managerreport"}>
              <button className="btn btn-primary mr-3 mb-4">
                Manager Report
              </button>
            </Link>
            <Link to={"/admin/paymentreport"}>
              <button className="btn btn-primary mr-3 mb-4">
                Payment Report
              </button>
            </Link>
            <Link to={"/admin/vendorpaymentreport"}>
              <button className="btn btn-primary mr-3 mb-4">
                Vendor Report
              </button>
            </Link>
            <Link to={"/admin/bankwisereport"}>
              <button className="btn btn-primary mr-3 mb-4">
                Bankwise Report
              </button>
            </Link>
            <Link to={"/admin/oustandingpaymentreport"}>
              <button className="btn btn-primary mr-4 mb-4">
                Outstanding Report{" "}
              </button>
            </Link>
          </div>
          <h2 className="text-[25px] ">Outstanding Report</h2>
          <div className="flex items-center justify-between w-full p-2 flex-wrap gap-2">
            {/* Search Bar */}
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Search by Customer, Event, or Contact"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  handleFilter(); // Filter on input change
                }}
                className="form-control"
                style={{ padding: "10px", marginRight: "10px", width: "250px" }}
              />
            </div>

            {/* Customer Filter */}
            <div className="dropdown">
              <button
                className="custom-button-reports dropdown-toggle"
                type="button"
                id="customerDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Filter By Customer
              </button>
              <div className="dropdown-menu" aria-labelledby="customerDropdown">
                <button
                  className="dropdown-item"
                  onClick={() => setCustomerFilter("")}
                >
                  All Customers
                </button>
                {customers.map((customer, index) => (
                  <button
                    key={index}
                    className="dropdown-item"
                    onClick={() => {
                      setCustomerFilter(customer);
                      handleFilter(); // Filter on customer selection
                    }}
                  >
                    {customer}
                  </button>
                ))}
              </div>
            </div>

            {/* Event Filter */}
            <div className="dropdown">
              <button
                className="custom-button-reports dropdown-toggle"
                type="button"
                id="eventDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Filter By Event
              </button>
              <div className="dropdown-menu" aria-labelledby="eventDropdown">
                <button
                  className="dropdown-item"
                  onClick={() => setEventFilter("")}
                >
                  All Events
                </button>
                {events.map((event, index) => (
                  <button
                    key={index}
                    className="dropdown-item"
                    onClick={() => {
                      setEventFilter(event);
                      handleFilter(); // Filter on event selection
                    }}
                  >
                    {event}
                  </button>
                ))}
              </div>
            </div>

            {/* Date Range Selection */}
            <div className="flex gap-2">
              From
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  handleFilter(); // Filter on date change
                }}
                className="form-control"
                style={{ marginRight: "10px" }}
              />
              To
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  handleFilter(); // Filter on date change
                }}
                className="form-control"
              />
            </div>

            <div className="grid md:flex mt-1 gap-1">
              {/* <button className="btn btn-success" onClick={handleFilter}>
                Apply
              </button> */}
              <button className="btn btn-danger" onClick={handleClear}>
                Clear Filters
              </button>
            </div>
          </div>

          <div>
            <p>Total number of payments: {filteredData.length}</p>
            <button className="btn btn-primary mb-3" onClick={exportToExcel}>Export to Excel</button>
          </div>

          {/* Table to display data */}
          <div className="overflow-y-auto h-[50vh] md:mt-0 w-full">
            <table className="table table-bordered bg-white">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th>Customer Name</th>
                  <th>Event Name</th>
                  <th>Event Date</th>
                  <th>Client Contact</th>
                  <th>Total Bill</th>
                  <th>Payment Recevied </th>
                  <th>Balance Amount</th>
                  <th>Payment Status</th>
                  <th>View More</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.customerName}</td>
                      <td>{item.event_name}</td>
                      <td>{item.event_date}</td>
                      <td>{item.contact}</td>
                      <td>{item.total_event_amount}</td>
                      <td>{item.adv_payment}</td>
                      <td>{item.rem_payment}</td>
                      <td>
                        <span
                          style={{
                            fontWeight: "bold",
                            color:
                              item.payment_status === "pending"
                                ? "red"
                                : item.payment_status === "done"
                                ? "green"
                                : "black",
                          }}
                        >
                          {item.payment_status}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleShowModal(item)}
                        >
                          View More
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Modal for displaying selected item details */}
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            dialogClassName="modal-dialog-centered modal-dialog-responsive"
          >
            <Modal.Header>
              <Modal.Title>Outstanding Report Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedItem ? (
                <div>
                <p style={{ lineHeight: "35px" }}>
                    <strong>Customer Name:</strong> {selectedItem.customerName}
                    <br />
                    <strong>Event Name:</strong> {selectedItem.event_name}
                    <br />
                    <strong>Event Date:</strong> {selectedItem.event_date}
                    <br />
                    <strong>Client Contact:</strong> {selectedItem.contact}
                    <br />
                    <strong>Total Bill:</strong>{" "}
                    {selectedItem.total_event_amount}
                    <br />
                    <strong>Payment Recevied :</strong> {selectedItem.adv_payment}
                    <br />
                    <strong>Balance Amount:</strong>{" "}
                    {selectedItem.rem_payment}
                    <br />
                    <strong>Payment Status:</strong>{" "}
                    {selectedItem.payment_status}
                  </p>
                </div>
              ) : (
                <p>No details available.</p>
              )}
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
              <button className="close-button-popup" onClick={handleCloseModal}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default OutstandingReport;

import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Header from "../../Sidebar/Header";
import "./NewGodown.css";

const NewGodowns = () => {
  const [formData, setFormData] = useState({
    Category: "",
    SubCategory: "",
    Stock_Name: "",
    Stock_Quantity: "",
    Price: "",
    Vendor_Id: "",
    Vendor_Name: "",
    unit: "",
  });

  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [errors, setErrors] = useState({});

  const unitOptions = [
    { name: "Kg", value: "Kg" },
    { name: "Pcs", value: "Pcs" },
    { name: "Ltr", value: "Ltr" },
    { name: "Gram", value: "Gram" },
    { name: "Dozen", value: "Dozen" },
    { name: "Box", value: "Box" },
    { name: "Meter", value: "Meter" },
    { name: "Pack", value: "Pack" },
    { name: "Ton", value: "Ton" },
    { name: "Number", value: "Number" },
  ];

  const handleUnitChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedUnit(selectedValue);
    setFormData((prevData) => ({
      ...prevData,
      unit: selectedValue,
    }));
  };

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await fetch("https://backend.ssdpune.org/api/addvendor");
        if (response.ok) {
          const data = await response.json();
          setVendors(data);
        } else {
          console.error("Failed to fetch vendors");
        }
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    fetchVendors();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    const selectedVendorData = vendors.find(
      (vendor) => vendor.Vendor_Name === event.target.value
    );
    if (selectedVendorData) {
      setFormData({
        ...formData,
        Vendor_Id: selectedVendorData._id,
        Vendor_Name: selectedVendorData.Vendor_Name,
      });
    }
  };

  const isValidForm = () => {
    const newErrors = {};
  
    if (!formData.Category) {
      newErrors.Category = "Category name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.Category)) {
      // Allow spaces using \s, which represents a space in the regular expression
      newErrors.Category = "Category should contain only alphabets and spaces";
    }
  
    if (!formData.SubCategory) {
      newErrors.SubCategory = "Sub-Category name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.SubCategory)) {
      newErrors.SubCategory = "Sub-Category should contain only alphabets and spaces";
    }
  
    if (!formData.Stock_Name) {
      newErrors.Stock_Name = "Stock name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.Stock_Name)) {
      newErrors.Stock_Name = "Stock name should contain only alphabets and spaces";
    }
  
    // Add further validations for other fields if necessary
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleSubmitformData = async (event) => {
    event.preventDefault();

    if (!isValidForm()) {
      console.log("Form validation failed");
      return;
    }

    try {
      const response = await fetch("https://backend.ssdpune.org/api/inventory-stocks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        window.alert("Stock added successfully.");
        setFormData({
          Category: "",
          SubCategory: "",
          Stock_Name: "",
          Stock_Quantity: "",
          Price: "",
          Vendor_Id: "",
          Vendor_Name: "",
          unit: "",
        });
        setSelectedUnit(""); 
      } else {
        window.alert("Failed to add stock.");
      }
    } catch (error) {
      console.error("Error adding stock:", error);
      window.alert("Failed to add stock. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:w-[60%]">
          <h4 className="text-[30px] pl-[1em] mb-4">Add Stocks</h4>
          <Form onSubmit={handleSubmitformData}>
            {/* Form fields */}
            <div className="grid grid-cols-2 gap-4">
              <Form.Group controlId="Category">
                <Form.Label>Product Category:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Product Category"
                  name="Category"
                  value={formData.Category}
                  onChange={handleInputChange}
                  isInvalid={!!errors.Category}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Category}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="SubCategory">
                <Form.Label>Sub-Category:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sub-Category"
                  name="SubCategory"
                  value={formData.SubCategory}
                  onChange={handleInputChange}
                  isInvalid={!!errors.SubCategory}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.SubCategory}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="Stock_Name">
                <Form.Label>Stock Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Stock Name"
                  name="Stock_Name"
                  value={formData.Stock_Name}
                  onChange={handleInputChange}
                  isInvalid={!!errors.Stock_Name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Stock_Name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="Stock_Quantity">
                <Form.Label>Quantity:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Add Quantity"
                  name="Stock_Quantity"
                  value={formData.Stock_Quantity}
                  onChange={handleInputChange}
                  isInvalid={!!errors.Stock_Quantity}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Stock_Quantity}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="Price">
                <Form.Label>Price/Quantity:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Price/Quantity"
                  name="Price"
                  value={formData.Price}
                  onChange={handleInputChange}
                  isInvalid={!!errors.Price}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Price}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="SelectUnit">
                <Form.Label>Select Unit:</Form.Label>
                <Form.Select
                  name="unit"
                  value={selectedUnit}
                  onChange={handleUnitChange}
                  isInvalid={!!errors.unit}
                >
                  <option value="">Select Unit</option>
                  {unitOptions.map((unit) => (
                    <option key={unit.value} value={unit.value}>
                      {unit.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.unit}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="SelectVendor">
                <Form.Label>View Vendors:</Form.Label>
                <Form.Select
                  name="vendor"
                  value={selectedVendor}
                  onChange={handleVendorChange}
                  isInvalid={!!errors.Vendor_Name}
                >
                  <option value="">Select Vendor</option>
                  {vendors.map((vendor) => (
                    <option key={vendor._id} value={vendor.Vendor_Name}>
                      {vendor.Vendor_Name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.Vendor_Name}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="flex justify-center gap-4 mt-4">
              <Button type="submit" className="btn btn-primary">
                Add Stock
              </Button>
            
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default NewGodowns;
